<template>
    <div>
        <div class="sm:flex justify-between">
            <div class="font-bold text-primary text-xl">Change Address</div>
            <button
                @click="$router.push({ name: 'AccountAddressAdd' })"
                class="flex items-center font-medium bg-gray-200 hover:bg-gray-300 py-1 px-4 rounded-xl"
            >
                <font-awesome-icon class="mr-2" icon="fa-regular fa-circle-xmark" :transform="{ rotate: 45 }" />
                Add Address
            </button>
        </div>
        <ValidationObserver v-slot="{ invalid }" class="sm:grid sm:grid-cols-2 sm:gap-12 sm:gap-y-4 mt-8">
            <ValidationProvider name="alias" rules="required">
                <div class="input-field-label text-primary">Save Address as</div>
                <input
                    v-model="form.alias"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium"
                />
            </ValidationProvider>
            <ValidationProvider name="province" rules="required">
                <div class="input-field-label text-primary">Province</div>
                <select
                    v-model="form.provinceId"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium cursor-pointer"
                    @input="getCities"
                >
                    <option v-for="province in provinces" :key="province.id" :value="province.id">
                        {{ province.name }}
                    </option>
                </select>
            </ValidationProvider>
            <ValidationProvider name="name" rules="required">
                <div class="input-field-label text-primary">Name</div>
                <input
                    v-model="form.name"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium"
                />
            </ValidationProvider>
            <ValidationProvider name="city" rules="required">
                <div class="input-field-label text-primary">City</div>
                <select
                    v-model="form.cityId"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium cursor-pointer disabled:cursor-default"
                    :disabled="form.provinceId == null"
                    @input="getDistricts"
                >
                    <option v-for="city in cities" :key="city.id" :value="city.id">
                        {{ city.name }}
                    </option>
                </select>
            </ValidationProvider>
            <ValidationProvider name="phone" :rules="{ required: true, regex: /^[0-9\s]+$/ }">
                <div class="input-field-label text-primary">Phone</div>
                <input
                    v-model="form.phone"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium"
                />
            </ValidationProvider>
            <ValidationProvider name="district" rules="required">
                <div class="input-field-label text-primary">District</div>
                <select
                    v-model="form.districtId"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium cursor-pointer disabled:cursor-default"
                    :disabled="form.cityId == null"
                >
                    <option v-for="district in districts" :key="district.id" :value="district.id">
                        {{ district.name }}
                    </option>
                </select>
            </ValidationProvider>
            <ValidationProvider name="address" rules="required">
                <div class="input-field-label text-primary">Address</div>
                <textarea
                    v-model="form.address"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium"
                />
            </ValidationProvider>
            <ValidationProvider name="postal code" :rules="{ required: true, regex: /^[0-9\s]+$/ }">
                <div class="input-field-label text-primary">Postal Code</div>
                <input
                    v-model="form.postalCode"
                    class="bg-gray-200 rounded-lg focus:outline-none w-full py-2 pl-2 font-medium"
                />
            </ValidationProvider>
            <div class="flex justify-between mt-8">
                <button @click="$router.push({ name: 'AccountAddressIndex' })" class="button-cancel">Cancel</button>
                <button @click="updateAddress" :disabled="invalid" class="button-submit">Update</button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    export default {
        name: 'AccountAddressEdit',

        data() {
            return {
                form: {
                    alias: null,
                    provinceId: null,
                    cityId: null,
                    districtId: null,
                    name: null,
                    phone: null,
                    address: null,
                    postalCode: null
                },
                provinces: [],
                cities: [],
                districts: []
            };
        },

        beforeRouteEnter(to, from, next) {
            if (Object.keys(to.params).length == 0) {
                next({ name: 'AccountAddressIndex' });
            } else {
                next();
            }
        },

        async created() {
            this.$store.commit('setOverlayLoading', true);

            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Account Setting', routeName: 'AccountProfile' },
                { text: 'My Address', routeName: 'AccountAddressIndex' },
                { text: 'Change Address', routeName: 'AccountAddressEdit' }
            ]);

            this.form = {
                alias: this.$route.params.address.nama_simpan,
                provinceId: this.$route.params.address.provinsi,
                cityId: this.$route.params.address.kabkota,
                districtId: this.$route.params.address.kecamatan,
                name: this.$route.params.address.nama,
                phone: this.$route.params.address.telepon,
                address: this.$route.params.address.alamat_lengkap,
                postalCode: this.$route.params.address.kode_pos
            };

            try {
                const response = await Promise.all([
                    this.$http.get('https://payment.malldesa.com/Login/api/kotaprov/prov'),
                    this.$http.get(`https://payment.malldesa.com/Login/api/kotaprov/listkota/${this.form.provinceId}`),
                    this.$http.get(`https://payment.malldesa.com/Login/api/kotaprov/listkecamatan/${this.form.cityId}`)
                ]);

                this.provinces = response[0].data.map(val => ({ id: val.province_id, name: val.province }));
                this.cities = response[1].data.map(val => ({ id: val.city_id, name: val.city }));
                this.districts = response[2].data.map(val => ({ id: val.subdistrict_id, name: val.subdistrict }));
            } catch (error) {
                this.$toasted.global.error(error);
            }

            this.$store.commit('setOverlayLoading', false);
        },

        methods: {
            async getCities() {
                this.$store.commit('setOverlayLoading', true);

                await this.$nextTick();

                this.cities = [];
                this.districts = [];
                this.form.cityId = null;
                this.form.districtId = null;

                try {
                    const response = await this.$http.get(
                        `https://payment.malldesa.com/Login/api/kotaprov/listkota/${this.form.provinceId}`
                    );
                    this.cities = response.data.map(val => ({ id: val.city_id, name: val.city }));
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            },
            async getDistricts() {
                this.$store.commit('setOverlayLoading', true);

                await this.$nextTick();

                this.districts = [];
                this.form.districtId = null;

                try {
                    const response = await this.$http.get(
                        `https://payment.malldesa.com/Login/api/kotaprov/listkecamatan/${this.form.cityId}`
                    );
                    this.districts = response.data.map(val => ({ id: val.subdistrict_id, name: val.subdistrict }));
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            },
            async updateAddress() {
                this.$store.commit('setOverlayLoading', true);

                const data = new FormData();
                data.append('txtIdUser', this.$store.state.user.id_user);
                data.append('txtIdAlamatPengiriman', this.$route.params.address.id_alamat_user);
                data.append('txtNama', this.form.name);
                data.append('txtNamaSimpan', this.form.alias);
                data.append('txtNoTelepon', this.form.phone);
                data.append('txtIDProvinsi', this.form.provinceId);
                data.append('txtIDKabkota', this.form.cityId);
                data.append('txtIDKecamatan', this.form.districtId);
                data.append('txtNamaProvinsi', this.provinces.find(val => val.id == this.form.provinceId).name);
                data.append('txtNamaKabkota', this.cities.find(val => val.id == this.form.cityId).name);
                data.append('txtNamaKecamatan', this.districts.find(val => val.id == this.form.districtId).name);
                data.append('txtKodePos', this.form.postalCode);
                data.append('txtAlamat', this.form.address);
                data.append('txtAlamatLengkap', this.form.address);
                data.append('mobile', 'android');

                try {
                    const response = await this.$http.post(
                        `${this.$apiTripwe}/ic/jetski-android-new/update_alamat_pengiriman_dv15.php`,
                        data
                    );

                    if (response.data != 'success') {
                        throw 'Update address failed';
                    }

                    this.$router.push({ name: 'AccountAddressIndex' });
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            }
        }
    };
</script>
